import React from 'react'
import Tabs from "../../components/tabs"
type Props = {
    children?: any;
}

function LegalInfo({
    children
}: Props) {
    return (
        <div className='-max-w-1366 mx-auto'>
            <div className='py-16 px-2 md:px-0'>
                <div style={{
                    border: "1px solid #f1f1f1"
                }}>
                    <Tabs >
                        <div title="Representative's Example">
                            If you borrowed $2,000 over a 12 month period and the loan had a 3% arrangement fee ($60), your monthly repayments would be $189.12, with a total payback amount of $2269.44 which including the 3% fee paid from the loan amount, would have a total cost of $329.44. Representative 29.82% APR.
                            <br /><br />
                            If you borrowed $5,000 over a 48 month period and the loan had an 8% arrangement fee ($400), your monthly repayments would be $131.67, with a total payback amount of $6320.12 which including the 8% fee paid from the loan amount, would have a total cost of $1720.12. Representative 18.23% APR.
                        </div>
                        <div title="Criteria">
                            It will just take a few minutes of your time to complete the loan offer form. We are dedicated to assisting you in obtaining the loan amount you require, and we can typically inform you of the outcome of your application immediately. Getting you the money you need when you need it the most.
                            <br />
                            <br />
                            To be eligible to apply, you must be at least 18 years old, have a regular paycheck, a permanent address, and a valid bank account. Similarly, the number of loan lenders available to you may be limited depending on your residence state. There will be no need for an in-person meeting, a phone interview, or for you to produce any papers.
                        </div>
                        <div title="APR">
                            loan-tree.co.nz refers consumers to trusted, reputable lenders who are able to provide loan information and advice – we do not charge for this service.
                            <br /><br />
                            loan-tree.co.nz is not a lender. Because of this, we are not able to confirm the exact rate of APR that you will be charged. Should you have reached our site via a paid Google advert, you will have seen repayment terms ranging from 6 to 60 months, together with illustrated rates of no greater than 25.95% APR (calculation consistent with the Truth In Lending Act, TILA).
                            <br /><br />
                            To apply for a loan, you should be 18 years or older. The rate of APR you will be charged will be set by the moneylender. The figure can differ and, will be based both on the information that you provide to the lender within your loan request and the data the loan provider supplied to you. The lender will always notify you of the APR they can offer you.
                            <br /><br />
                            APR rates are based on how creditworthy you are, the rates are subject to change without notice which could result in the rate and amount you pay back varying.
                            <br /><br />
                            Once your application has been acknowledged by a moneylender, they will provide you with all the facts associated with the loan. This will incorporate the APR, any related loan finance charges and all of the terms you will be agreeing to. It is prudent that you read all information submitted to you by the lender to ensure that you can make an informed decision prior to accepting a loan offer.
                            <br /><br />
                            If you have any questions related to APR or loan related services, please contact us for more assistance.
                        </div>

                        <div title='Disclosure'>
                            <strong>
                                Lender Network Disclosure :
                            </strong>
                            <br /><br />
                            loan-tree.co.nz is not a lender – we provide no loans, make no lending decisions, nor do we influence lenders in their decisions. We pride ourselves in providing a free, no-obligation service to consumers looking to borrow funds. We connect those very people, people like you, to trusted, reputable lenders who are willing to lend to them.
                            <br /><br />
                            When using loan-tree.co.nz to secure your loan requirements, you’ll benefit from our bespoke financial technology. Not only will you be able to access a network of lenders quickly and with ease with just one application, we’ll filter out the companies that don’t match your needs or personal circumstances.
                            <br /><br />
                            All of the lenders in our network employ their own unique lending criteria and have individual terms and conditions. With this in mind, when you’re approved for a loan and receive your no obligation loan offer, be sure to read the documents carefully to ascertain that it’s the right offer for you! Remember, you’re not obliged to proceed.

                            <br /><br />
                            <strong>
                                Credit Check Disclosure :
                            </strong>
                            <br /><br />
                            loan-tree.co.nz are not lenders so make NO loan or credit decisions. The trusted lenders that we work with may, however, carry out credit checks to ascertain how creditworthy you are, your credit standing and/or your credit capacity. There are three reporting credit bureaus that the credit checks may be carried out with: Experian, Equifax, and TransUnion. By submitting your loan request, you are agreeing to allow our lenders to verify your personal information and check your credit.
                            <br /><br />
                            Please borrow responsibly and remember that a missed or late payment can have a negative credit impact.
                            <br /><br />

                            <strong>
                                Truth in Lending Policy :
                            </strong>
                            <br /><br />
                            To work with loan-tree.co.nz, the lenders in our network must all comply with to the Truth in Lending Act. This means that they always supply loan offers and contractual agreements that are easily understood as best practice and as required by Federal Law. Truth in Lending helps protect you the consumer against predatory and unscrupulous lending.
                            <br /><br />
                            Please remember the choice to proceed with a loan is all yours, you are under no obligation to accept a loan offer. Before agreeing to go ahead with the agreement, please read all of the paperwork the lender provides you with care to ensure that you understand all of the information about your loan (APR, charges etc). You also need to consider and be certain that you can afford the repayments, and that you are totally satisfied with the offer that has been made to you before signing. If in doubt, ask the lender to clarify details. An informed choice is the best choice.
                            <br /><br />

                            <strong>
                                State Availability Disclosure :
                            </strong>
                            <br /><br />
                            The State availability of our services via our reputable lending network is entirely dependent on lenders that operate or offer their financial products and services within any particular state. Be aware that some lenders may charge APR rates that exceed the limit of the specific State Usury Law, because of this, they can’t legally operate within that State.
                            <br /><br />
                            The amount of interest that you can legally be charged is set by each State. These Usury Laws are there to protect consumers from unscrupulous lenders and excessive interest rates. Usury Laws are somewhat complicated by a multitude of legal loopholes and exceptions – resulting in it frequently becoming necessary for a rate for each situation.
                            <br /><br />
                            As a consumer, you have the right to waive the Usury Law set interest rates, this can be done via your contract with the lender. As a result, State Interest Rate Laws that apply to your State of residence may have no bearing on the actual rates you have agreed to pay.
                            <br /><br />
                            Lending criteria vary from State to State, this will mean that not all of our trusted lenders operate in every State. In fact, due to a lender’s eligibility criteria or State legislation, a lender has the option to decline your application and residents of some US states may not be eligible for instalment loans products at all.
                            <br /><br />
                            loan-tree.co.nz connects consumers with lenders that adhere to the specific State Usury Laws and that charge (including fees and interest), a minimum APR rate of 3.95% to a maximum APR rate of 25.95%, but within the legal limits that have been set by that State. Responsibility for ensuring that any agreement you enter into meets State legislation is that of the lender.
                            <br /><br />
                            A useful point of contact regarding any concerns you may have regarding unlawful interest rates would be your State Office. They are best placed to advise you on the Usury Laws that will affect you.
                            <br /><br />
                            loan-tree.co.nz does not undertake solicitation for loan products and does not constitute a loan offer for any loan products that are prohibited by State Law. This service is void where prohibited.
                        </div>
                        <div title="Implications">
                            <strong>
                                Late or Missed Payments :
                            </strong>
                            <br /><br />
                            When accepting the terms and conditions of your personal loan, you are agreeing to repay the loan both: A) with interest and B) in the time frame specified in the loan agreement. Generally, failure to repay the loan in full, making a late payment or, missing a payment, will result in you being charged additional fees by your lender.
                            <br /><br />
                            We have NO ability to predict or estimate what supplemental charges will be incurred in the event of late, partial, or non-payment of loans here at loan-tree.co.nz. Nor have we any control or knowledge of any loan agreements or details between you and your lender.
                            <br /><br />
                            Within your Loan Agreement, you will be provided with details on the late, partial, and non-payment policies that your lender enforces, please review this fully prior to proceeding with a loan offer. loan-tree.co.nz strives to partner with only the most trustworthy and reputable lenders, those who pursue the collection of past-due loan accounts in a fair and reasonable manner, ensuring you have the best loan experience.
                            <br /><br />

                            <strong>
                                Credit Score Impact :
                            </strong>
                            <br /><br />
                            To ascertain how creditworthy you are, your credit capacity and/or your credit standing, our network of lenders may perform credit checks. Submitting a loan request means that you are agreeing to allow our lenders to verify your personal information and check your credit. Note: missing payments and/or late payments may have a negative impact your credit score.
                            <br /><br />

                            <strong>
                                Collection Practices :
                            </strong>
                            <br /><br />
                            loan-tree.co.nz only works with reputable lenders who use fair collection practices.
                            <br /><br />
                            As a non-lender, loan-tree.co.nz has NO involvement in the debt collection process of your loan. Your lender will disclose all of their debt collection processes within your Loan Agreement. Should you have any questions regarding debt collections, please contact your loan provider.
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default LegalInfo