import React, { ReactElement } from "react"
import Button from "../../components/button"
import Loader from "../../components/loader"
import Select from "../../components/select"
import Data from "../../data/sofortkredit"
import ApplicationFormFull, { IFormSchema } from "../../hoc/application-form-full"
import CommonPoints from "../../hoc/common-points"
import RepsInfo from "../../hoc/reps-content"
import Utils from "../../utils"

interface Props {
  title?: string;
  description?: string;
  referer?: string;
}

function ApplyPage({
  title,
  description,
  referer
}: Props): ReactElement {
  const [formData, setFormData] = React.useState({} as any)
  const [loading, setLoading] = React.useState(false)
  const [formSubmissionDone, setFormSubmissionDone] = React.useState(false)
  const [onSubmitError, setOnSubmitError] = React.useState("")
  const [bestTimeToReach, setBestTimeToReach] = React.useState("")
  const [doneFinal, setDoneFinal] = React.useState(false)

  const schema: IFormSchema[] = [
    {
      title: "APPLY NOW",
      fields: [
        {
          validation: "required",
          label: "Amount",
          title: "Amount",
          name: "loanAmount",
          type: "select",
          items: [
            {
              title: "$1000  - $2000",
              value: "1000 - 2000",
            },
            {
              title: "$2000  - $3000 ",
              value: "2000 - 3000",
            },
            {
              title: "$3000  - $5000 ",
              value: "3000 - 5000",
            },
            {
              title: "$5000  - $10000 ",
              value: "5000 - 10000",
            },
            {
              title: "$10000  - $15000 ",
              value: "10000 - 15000",
            },
            {
              title: "$15000  - $20000 ",
              value: "15000 - 20000",
            },
            {
              title: "$20000  - $35000 ",
              value: "20000 - 35000",
            },
            {
              title: "$35000  - $50000 ",
              value: "35000 - 50000",
            },
          ],
        },
        {
          label: "Purpose",
          name: "purpose",
          title: "Purpose",
          type: "select",
          items: [
            { value: "Personal", title: "Personal" },
            { value: "Debt Consolidation", title: "Debt Consolidation" },
            { value: "Emergency", title: "Emergency" },
            { value: "Medical Expenses", title: "Medical Expenses" },
            { value: "Auto Repair", title: "Auto Repair" },
            { value: "Others", title: "Others" },
          ],
          spread: true,
        },
        {
          title: "First Name",
          label: "First Name*",
          name: "firstName",
          type: "input",
          placeholders: ["Required"],
          validation: "required",
        },
        {
          title: "Last Name",
          label: "Last name",
          name: "lastName",
          type: "input",
          placeholders: [""],
        },
        // {
        //   label: "Gender",
        //   type: "select",
        //   items: [
        //     { value: "Female", title: "Female" },
        //     { value: "Male", title: "Male" },
        //   ],
        //   name: "gender",
        //   spread: true,
        // },

        {
          title: "Email",
          label: "Email*",
          name: "email",
          type: "input",
          subType: "text",
          placeholders: ["Required"],
          validation: "email",
        },

        {
          title: "Phone",
          label: "Phone*",
          name: "phone",
          type: "input",
          subType: "phone",
          prefix: "+64",
          placeholders: [""],
          validation: "required",
        },
        {
          label: "Employment Status",
          name: "employmentStatus",
          title: "Employment Status",
          type: "select",
          items: [
            { value: "", title: "--Select--" },
            { value: "Employed", title: "Employed" },
            { value: "Un-Employed", title: "Un-Employed" },
          ],
        },
        {
          title: "Date Of Birth",
          label: "Date Of Birth",
          name: "dateOfBirth",
          type: "input",
          subType: "dob",
          placeholders: ["DD", "MM", "YYYY"],
        },
        // {
        //   label: "Marital Status",
        //   name: "maritalStatus",
        //   title: "Marital Status",
        //   type: "select",
        //   items: [
        //     { value: "Single", title: "Single" },
        //     { value: "Married", title: "Married" },
        //     { value: "Divorced", title: "Divorced" },
        //     { value: "Other", title: "Other" },
        //   ],
        //   spread: true,
        // },
        // {
        //   label: "No Of Dependents",
        //   name: "noOfDependents",
        //   title: "No Of Dependents",
        //   type: "select",
        //   items: [
        //     { value: "None", title: "None" },
        //     { value: "1", title: "One" },
        //     { value: "2", title: "Two" },
        //     { value: "3+", title: "Three+" },
        //   ],
        //   spread: true,
        // },
        // {
        //   label: "Home Status",
        //   name: "homeStatus",
        //   title: "Home Status",
        //   type: "select",
        //   items: [
        //     { value: "Owner", title: "Owner" },
        //     { value: "Rent", title: "On Rent" },
        //     { value: "Family", title: "Living With Family" },
        //     { value: "Other", title: "Other" },
        //   ],
        //   spread: true,
        // },
        {
          title: "Street Address",
          label: "Street Address",
          name: "streetAddress",
          type: "input",
          subType: "text",
          placeholders: [""],
        },
        {
          title: "Province",
          label: "Province",
          name: "state",
          type: "select",
          items: [
            { value: "--", title: "--Select--" },
            { value: "Northland", title: "Northland" },
            { value: "Thames Valley", title: "Thames Valley" },
            { value: "Waikato	October ", title: "Waikato	October " },
            { value: "Bay of Plenty", title: "Bay of Plenty" },
            { value: "Tongariro", title: "Tongariro" },
            { value: "East Cape", title: "East Cape" },
            { value: "Hawke's Bay", title: "Hawke's Bay" },
            { value: "Taranaki", title: "Taranaki" },
            { value: "Wanganui", title: "Wanganui" },
            { value: "Wairarapa", title: "Wairarapa" },
            { value: "Manawatu", title: "Manawatu" },
            { value: "Horowhenua", title: "Horowhenua" },
            { value: "Nelson Bays", title: "Nelson Bays" },
            { value: "Marlborough", title: "Marlborough" },
            { value: "Canterbury", title: "Canterbury" },
            { value: "West Coast", title: "West Coast" },
            { value: "Aorangi", title: "Aorangi" },
            { value: "Coastal / North Otago", title: "Coastal / North Otago" },
            { value: "Clutha / Central Otago", title: "Clutha / Central Otago" },
            { value: "Southland", title: "Southland" },
          ],
        },
        {
          title: "Referer.",
          label: "Referer.",
          name: "referer",
          type: "hidden",
          subType: "text",
          defaultValue: (referer || "LNTRE") + (typeof window !== "undefined" ? window.location.pathname : ""),
          placeholders: ["Optional"],
        },
      ],
    },

  ]

  const bestTimesToReach = [
    {
      title: "Within 15 min",
      value: "15 min",
    },
    {
      title: "Within 15 - 30 min",
      value: "15 - 30 min",
    },
    {
      title: "Within 30-45 min",
      value: "30 - 45 min",
    },
    {
      title: "Within 1 Hr",
      value: "1 Hr",
    },
  ]

  const onFormChange = (fd: any) => {
    setFormData(fd)
  }

  const onFormSubmit = async (fd: any) => {
    Utils.Commons.scrollTo("AFC_FORM");
    setOnSubmitError("")
    setLoading(true)
    try {
      const { data } = await Utils.API.post("eusers/register", fd)
      if (data === "OK") {
        setFormSubmissionDone(true)
      } else {
        setOnSubmitError(
          "Server error occurred! Please try again later."
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const onChangeBestTimeToReach = (val: any) => {
    setBestTimeToReach(val)
  }

  const updateTimeToReach = async (e: any) => {
    const fd = { ...formData, bestTimeToReach }
    Utils.Commons.scrollTo("AFC_FORM");
    setOnSubmitError("")
    setLoading(true)
    try {
      const { data } = await Utils.API.post("eusers/updateuser", fd)
      if (data === "OK") {
        setFormSubmissionDone(true)
        setDoneFinal(true)
      } else {
        setOnSubmitError(
          "Server error occurred! Please try again later."
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <div className="-max-w-1366 mx-auto">

      {/* <div className="max-w-1366">{JSON.stringify(formData)}</div> */}
      <div className="">
        <div className="text-center pb-8">
          <h3 className="text-2xl md:text-3xl">
            {title}
          </h3>
          <h5 className="text-gray-400 pt-4">
            {description}
          </h5>
        </div>
      </div>
      <div className="bg-gray-100 max-w-1366 shadow-lg rounded-lg overflow-hidden">
        <Loader visible={loading} />
        {onSubmitError && onSubmitError !== "" ? (
          <div
            style={{ marginBottom: "-50px" }}
            className="p-4 bg-teal-700 text-white mt-8"
          >
            {onSubmitError}
          </div>
        ) : (
          ""
        )}

        {!formSubmissionDone ? (
          <>

            <div className="" id="AFC_FORM">
              <ApplicationFormFull
                className="p-8"
                onSubmit={onFormSubmit}
                onChange={onFormChange}
                schema={schema}
              />

            </div>
          </>
        ) : (
          <div className="max-w-1366 px-8 md:px-0 py-16">
            <div className="flex flex-col items-center my-8 px-8">
              <div>
                <img
                  className="w-16 h-16 rounded-full shadow-lg"
                  src={require("../../images/done-icon.svg").default}
                />
              </div>
              <div>
                <h4 className="text-2xl font-bold mt-8">
                  Submitted successfully
                </h4>
              </div>
              <div>
                <h4 className="text-xl font-bold mt-2 text-justify">
                  {doneFinal
                    ? `Perfect! One of our agents will reach you within ${bestTimeToReach}.`
                    : `Congratulations! Your loan application has been submitted.
                    Please choose the best time to contact you.`}
                </h4>
              </div>
              {!doneFinal ? (
                <>
                  <div className="my-4">
                    <Select
                      onChange={onChangeBestTimeToReach}
                      options={bestTimesToReach}
                      spread
                      autoSelect={false}
                    />
                  </div>
                  {bestTimeToReach && bestTimeToReach.length > 0 ? (
                    <div className="my-4">
                      <Button title="OK" onClick={updateTimeToReach} />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>

      {/* <RepsInfo /> */}
    </div>
  )
}

export default ApplyPage
