import React, { ReactElement } from "react";
import SlickSlider from "../../components/slick";
import Button from "../../components/button";
import Utils from "../../utils";
import { Link } from "gatsby";

interface IFaq {
  author?: string;
  title: string;
  description: string;
  bg?: string;
}
export interface FaqsProps { }

export default function Faqs(props: FaqsProps): ReactElement | null {
  const faqs: IFaq[] = [
    {
      title: "Can I apply for an instant loan if I am unemployed?",
      description: `Yes, you can apply for a loan, but you should have a proof like a identity card. So you will easily get a loan.`,
    },
    {
      title: "How does the personalized interest rate work?",
      description: `The interest rate for your loan depends on your credit history can be between 1.00% and 15.99% per year.`,
    },
    {
      title: "What are the fees for the loan application?",
      description: `There are no fees for the application or use of your credit line. The opening of the account will not cost you anything. You can always withdraw more money or repay your balance without charges. They only pay interest rates (1.00% - 15.99% per year) for the money they lifted from their credit framework.`,
    },
    {
      title: "Why should I go for a credit line?",
      description: `The main advantages of an instant loan are accessibility and flexibility. The funds can be easily lifted and used for any purpose they need. You can also make additional repayments at any time to your account, which allows you to save interest rates. There are no fees for the opening of the account.`,
    },
    {
      title: "What is a credit line?",
      description: `An instant loan is a flexible loan. If you submit an application, you will receive an accountimize that you can use at once or as little as necessary. They only pay interest rates for the credit that they have lifted. You can always withdraw more money from your available balance without having to complete another application. Log in here to view your available credit or to make a withdrawal.`,
    },
    {
      title: "Can I refinance existing loans with instant complete loans?",
      description: `If you use a part of a loan of immediate 247 financial repayment of existing loans / debts (including the merger of these loans to a single), you should definitely read the section "Considerations for debt consolidation" in our credit instinct. Alternatively, you can also talk to our team, which you would like to inform about your possibilities: Email: info@loan-tree.co.nz`,
    },
  ];

  return (
    <div className="-max-w-1366 mx-auto ">
      <div className=" bg-gray-50 mt-16 py-8"  >
        <h2 className="text-center">FAQs</h2>
        {faqs.map((faq, index) => (
          <div key={index}
            className="flex flex-col p-8 "
          >
            <h4 >
              {index + 1}. {faq.title}
            </h4>
            <p className="pl-4">
              {faq.description}
            </p>
          </div>
        ))}
      </div>
      <div className="p-8 md:p-0 flex flex-col md:flex-row items-center mt-12">
        <div className="flex-1 flex justify-center items-center">
          <img src={require("../../images/dream.png").default} />
        </div>
        <div className="flex-1 mt-8 md:mt-0 flex justify-center items-center">
          <div>
            <h1>You're only a few clicks away from reaching your ambition!!</h1>
            <div className="flex md:block justify-center mt-12">
              <Link to="/apply">
                <Button title={"APPLY NOW"}></Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
